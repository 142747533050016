/* global BigInt */

import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Navbar from "components/Navbar";
import { useCookies } from "react-cookie";
import useSessionStoredState from "utils/useSessionStoredState";

BigInt.prototype.toJSON = function () {
  return this.toString();
};

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.token === undefined) {
      navigate("/login");
    } else {
      navigate("/space");
    }
  }, []);

  return (
    <div>
      <Navbar
        links={
          cookies.token === undefined
            ? [
                { to: "/login", text: "Login" },
                { to: "/register", text: "Register" },
              ]
            : [{ to: "/space", text: "Space" }]
        }
      />
    </div>
  );
}

export default App;
