/* global BigInt */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BuildingsList from "components/BuildingsList";
import Navbar from "components/Navbar";
import base_url from "base_url";
import { useCookies } from "react-cookie";
import useSessionStoredState from "../utils/useSessionStoredState";

function StarSystem() {
  const [X, setX] = useSessionStoredState("X", BigInt(0), BigInt);
  const [Y, setY] = useSessionStoredState("Y", BigInt(0), BigInt);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [system, setSystem] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const current_time = () => Date.now() / 1000;
  const [time, setTime] = useState(current_time());
  const current_value = ({ offset, slope, last_time }) =>
    ((time - last_time) * slope + offset).toFixed(2);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => setTime(current_time()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const reload_user = () => {
    fetch(`${base_url}/users/me`, {
      headers: { Authorization: `Bearer ${cookies.token || ""}` },
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((data) => {
        console.log(data);
        setUser(data);
      });
  };

  const reload_system = () => {
    fetch(`${base_url}/star-system/${X}/${Y}/`, {
      headers: { Authorization: `Bearer ${cookies.token || ""}` },
    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((data) => {
        setSystem(data);
      });
  };

  const handleKeys = (event) => {
    if (event.key === "Escape") {
      navigate(`/space`);
    }
    const step = BigInt(1);
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setX(X);
      setY(Y - step);
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setX(X);
      setY(Y + step);
    }
    if (event.key === "ArrowLeft") {
      event.preventDefault();
      setX(X - step);
      setY(Y);
    }
    if (event.key === "ArrowRight") {
      event.preventDefault();
      setX(X + step);
      setY(Y);
    }
  };

  useEffect(() => {
    reload_system();
    reload_user();
    document.addEventListener("keydown", handleKeys);

    return () => {
      document.removeEventListener("keydown", handleKeys);
    };
  }, [X, Y]);

  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <div className="bg-gray-200 min-h-screen">
      <Navbar />
      {user && (
        <nav className="flex justify-evenly p-4 bg-indigo-500 text-white">
          {Object.entries(user.resources)
            .slice(1)
            .map((entry) => (
              <div
                key={entry[0]}
                className="flex bg-slate-200 text-black p-3 rounded"
              >
                <div className="w-24 font-bold">{capitalize(entry[0])}:</div>
                <div className="font-medium">{current_value(entry[1])}</div>
              </div>
            ))}
        </nav>
      )}
      <main className="p-4">
        {system && [
          <h2 className="text-2xl font-medium mb-4">
            Star system: ({system.gen.coord.x}, {system.gen.coord.y})
          </h2>,
          <div className="flex content-start auto-cols-auto gap-x-10 gap-4 overflow-x-auto">
            <div className="min-w-[100px]">
              <div className="text-lg font-medium my-2">Type - Id</div>
              <div className="text-gray-700">
                Size
                <br />
                Energy
                <br />
                Fuels
                <br />
                Minerals
                <br />
                Organics
                <br />
                Silicates
                <br />
                Strategics
                <br />
                Water
              </div>
            </div>
            <div className="min-w-[170px]">
              <div className="text-lg font-medium my-2">
                {system.gen.star.type}
              </div>
              <div className="text-gray-700">
                {system.gen.star.size}
                <br />
                {system.gen.star.energy}
                <br />
                {system.gen.star.fuels}
                <br />
                {system.gen.star.minerals}
                <br />
                {system.gen.star.organics}
                <br />
                {system.gen.star.silicates}
                <br />
                {system.gen.star.strategics}
                <br />
                {system.gen.star.water}
              </div>
              <div>
                <BuildingsList
                  builtBuildings={[]}
                  unbuiltBuildings={[
                    {
                      type: 0,
                      name: "Nothing",
                      description: "Nothing to see here",
                      cost: {
                        energy: 0,
                        fuels: 0,
                        minerals: 0,
                        organics: 0,
                        silicates: 0,
                        strategics: 0,
                        water: 0,
                        pops: 0,
                      },
                      production: {
                        energy: 0,
                        fuels: 0,
                        minerals: 0,
                        organics: 0,
                        silicates: 0,
                        strategics: 0,
                        water: 0,
                        pops: 0,
                      },
                    },
                  ]}
                />
              </div>
            </div>
            {system.gen.planets.map((planet, index) => (
              <div className="min-w-[170px]" key={`planet-${index}`}>
                <div className="text-lg font-medium my-2">
                  {planet.type} {index + 1}
                </div>
                <div className="text-gray-700">
                  {planet.size}
                  <br />
                  {planet.energy}
                  <br />
                  {planet.fuels}
                  <br />
                  {planet.minerals}
                  <br />
                  {planet.organics}
                  <br />
                  {planet.silicates}
                  <br />
                  {planet.strategics}
                  <br />
                  {planet.water}
                </div>
                <div>
                  <BuildingsList
                    builtBuildings={[]}
                    unbuiltBuildings={[
                      {
                        type: 0,
                        name: "Nothing",
                        description: "Nothing to see here",
                        cost: {
                          energy: 0,
                          fuels: 0,
                          minerals: 0,
                          organics: 0,
                          silicates: 0,
                          strategics: 0,
                          water: 0,
                          pops: 0,
                        },
                        production: {
                          energy: 0,
                          fuels: 0,
                          minerals: 0,
                          organics: 0,
                          silicates: 0,
                          strategics: 0,
                          water: 0,
                          pops: 0,
                        },
                      },
                      {
                        type: 0,
                        name: "Nothing 2",
                        description: "Nothing to see here",
                        cost: {
                          energy: 0,
                          fuels: 0,
                          minerals: 0,
                          organics: 0,
                          silicates: 0,
                          strategics: 0,
                          water: 0,
                          pops: 0,
                        },
                        production: {
                          energy: 0,
                          fuels: 0,
                          minerals: 0,
                          organics: 0,
                          silicates: 0,
                          strategics: 0,
                          water: 0,
                          pops: 0,
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            ))}
          </div>,
        ]}
      </main>
    </div>
  );
}

export default StarSystem;
