import React, { useState } from "react";

const BuildingCard = ({ name, description, cost, production, isBuilt }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white p-4 rounded-md shadow-md">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={handleClick}
      >
        <h2 className="text-lg font-semibold">{name}</h2>
        <span>{isBuilt ? "Built" : "Not Built"}</span>
      </div>
      {isOpen && (
        <div className="mt-2">
          <p className="text-gray-700">{description}</p>
          <div className="mt-2">
            <h3 className="text-md font-semibold">Cost:</h3>
            <ul className="list-disc ml-4">
              {Object.entries(cost).map(([resource, amount]) => (
                <li key={resource}>
                  {resource}: {amount}
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-2">
            <h3 className="text-md font-semibold">Production:</h3>
            <ul className="list-disc ml-4">
              {Object.entries(production).map(([resource, amount]) => (
                <li key={resource}>
                  {resource}: {amount}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildingCard;
