import { useRouteError } from "react-router-dom";

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        <img
          className="max-w-[300px]"
          src={process.env.PUBLIC_URL + "/oopsie.png"}
          alt="oopsie"
        />
        <h1 className="text-l font-bold">An error has occured:</h1>
        <i>{error.statusText || error.message}</i>
      </div>
    </div>
  );
}

export default ErrorPage;
