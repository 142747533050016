import { Link } from "react-router-dom";
import React from "react";

const Navbar = ({ links = [] }) => {
  return (
    <nav className="flex items-center justify-between p-4 bg-indigo-500 text-white">
      <Link to="/" className="font-bold text-xl">
        Untitled Space Game
      </Link>
      <div>
        {links.map((v) => (
          <Link to={v.to} className="px-4">
            {v.text}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
