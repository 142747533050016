import BuildingCard from "components/BuildingCard";
import React from "react";

const BuildingList = ({ builtBuildings, unbuiltBuildings }) => {
  return (
    <div className="flex flex-col gap-4">
      {unbuiltBuildings.map((building) => (
        <BuildingCard
          key={building.name}
          name={building.name}
          description={building.description}
          cost={building.cost}
          production={building.production}
          isBuilt={false}
        />
      ))}
      {builtBuildings.map((building) => (
        <BuildingCard
          key={building.name}
          name={building.name}
          description={building.description}
          cost={building.cost}
          production={building.production}
          isBuilt={true}
        />
      ))}
    </div>
  );
};

export default BuildingList;
