import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Navbar from "./Navbar";
import base_url from "../base_url";
import { useCookies } from "react-cookie";

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  useEffect(() => {
    if (cookies.token !== undefined) {
      navigate("/");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(base_url + "/auth/register", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
        is_active: true,
        is_verified: true,
      }),
      headers: { "Content-Type": "application/json" },
    });
    if (!response.ok) {
      const data = await response.json();
      setError(data);
    } else {
      navigate("/login");
    }
  };

  const isValid =
    email.length > 3 && password.length > 0 && password === passwordAgain;

  return (
    <div>
      <Navbar />
      <div className="flex justify-center">
        <form
          className="p-6 rounded-lg w-full max-w-sm"
          onSubmit={handleSubmit}
        >
          <h2 className="text-lg font-medium mb-4">Register</h2>
          {error && <p className="text-red-500">{error}</p>}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Email
            </label>
            <input
              className="border border-gray-400 p-2 rounded-lg w-full"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Password
            </label>
            <input
              className="border border-gray-400 p-2 rounded-lg w-full"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Password Again
            </label>
            <input
              className="border border-gray-400 p-2 rounded-lg w-full"
              type="password"
              value={passwordAgain}
              onChange={(e) => setPasswordAgain(e.target.value)}
            />
          </div>
          <div className="flex justify-between">
            <button
              disabled={!isValid}
              className="bg-indigo-500 text-white p-2 rounded-lg hover:bg-indigo-600 disabled:bg-gray-500"
            >
              Register
            </button>
            <Link
              className="bg-indigo-500 text-white p-2 rounded-lg hover:bg-indigo-600"
              to="/login"
            >
              Login instead
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
