/* global BigInt */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Navbar from "components/Navbar";
import base_url from "base_url";
import debounce from "utils/debounce";
import { hasUnreliableEmptyValue } from "@testing-library/user-event/dist/utils";
import { useCookies } from "react-cookie";
import useSessionStoredState from "utils/useSessionStoredState";
import useWindowDimensions from "hooks/useWindowDimensions";

function fitCells(windowWidth, windowHeight) {
  let cellSize = 250;
  const minSize = 150;
  for (; cellSize >= minSize; cellSize--) {
    let cellsAcross = Math.floor(windowWidth / cellSize);
    let cellsDown = Math.floor(windowHeight / cellSize);
    if (cellsAcross % 2 === 0) {
      cellsAcross--;
    }
    if (cellsDown % 2 === 0) {
      cellsDown--;
    }
    if (
      windowWidth - cellSize * cellsAcross < cellSize / 2 &&
      windowHeight - cellSize * cellsDown < cellSize / 2
    ) {
      return {
        cellSize: cellSize,
        cellsAcross: cellsAcross,
        cellsDown: cellsDown,
      };
    }
  }

  return {
    cellSize: minSize,
    cellsAcross: Math.floor(windowWidth / cellSize),
    cellsDown: Math.floor(windowHeight / cellSize),
  };
}

class Obj {
  constructor(cx, cy, obj) {
    this.cx = cx;
    this.cy = cy;
    this.obj = obj;
  }

  draw(ctx) {}
}

function SpaceMap() {
  const { height, width } = useWindowDimensions();
  const { cellSize, cellsAcross, cellsDown } = fitCells(width, height);
  let sizeX = cellsAcross;
  let sizeY = cellsDown;
  if (sizeX % 2 === 0) {
    sizeX -= 1;
  }
  if (sizeY % 2 === 0) {
    sizeY -= 1;
  }
  const centerX = Math.floor(sizeX / 2);
  const centerY = Math.floor(sizeY / 2);
  const size = {
    width: sizeX * cellSize,
    height: sizeY * cellSize,
  };
  const gridSize = {
    rows: sizeY,
    columns: sizeX,
  };
  const [starSystems, setStarSystems] = useState(undefined);
  const [knownCoordiates, setKnownCoordiates] = useState([]);
  const [X, setX] = useSessionStoredState("X", BigInt(0), BigInt);
  const [Y, setY] = useSessionStoredState("Y", BigInt(0), BigInt);
  const systems_cache = {};
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const canvasRef = useRef(null);

  const starColors = {
    "Main Sequence Star": "yellow",
    "Brown Dwarf Star": "brown",
    "Red Dwarf Star": "red",
    "Giant Star": "blue",
    "Super Giant Star": "white",
  };

  const planetColors = {
    "Rocky Planet": "SaddleBrown",
    "Desert Planet": "Khaki",
    "Lava Planet": "Tomato",
    "Ice Planet": "AliceBlue",
    "Gaia Planet": "LawnGreen",
    "Oceanic Planet": "DeepSkyBlue",
    "Toxic Planet": "LimeGreen",
    "Jungle Planet": "DarkOliveGreen",
    "Crystal Planet": "LightPink",
    "Barren Planet": "Silver",
    "Alien Planet": "MediumPurple",
    "Terraformed Planet": "PaleGreen",
    "Gas Giant Planet": "LightSalmon",
    "Ice Giant Planet": "LightCyan",
    "Alien Planet": "Indigo",
  };

  function openStarSystem(event) {
    if (!canvasRef.current) return;
    event.preventDefault();
    event.stopPropagation();
    var rect = canvasRef.current.getBoundingClientRect();
    const x = Math.floor((event.clientX - rect.left) / cellSize);
    const y = Math.floor((event.clientY - rect.top) / cellSize);
    const _X = BigInt(x - centerX) + X;
    const _Y = BigInt(y - centerY) + Y;
    setX(_X);
    setY(_Y);
    navigate(`/system`);
  }
  function drawSelectBox(event) {}

  const reload = debounce(() => {
    fetch(
      `${base_url}/star-system/${X - BigInt(1 + centerX)}/${
        X + BigInt(sizeX + 1 + centerX)
      }/${Y - BigInt(1 + centerX)}/${Y + BigInt(sizeY + 1 + centerX)}/`,
      {
        headers: { Authorization: `Bearer ${cookies.token || ""}` },
      }
    )
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((data) => {
        setStarSystems(data);
      });
  }, 500);

  fetch(`${base_url}/users/me`, {
    headers: { Authorization: `Bearer ${cookies.token || ""}` },
  })
    .then((Response) => {
      if (Response.ok) {
        return Response.json();
      }
    })
    .then((data) => {
      console.log(data);
    });

  const get_system = (x, y) => {
    if (
      starSystems === undefined ||
      starSystems[x] === undefined ||
      starSystems[x][y] === undefined
    ) {
      return undefined;
    }
    const k = `${x}/${y}`;
    let c = JSON.parse(sessionStorage.getItem(k));
    if (!c) {
      const json = starSystems[x][y];
      const data = {
        type: json.star.type,
        color: starColors[json.star.type],
        size: json.star.size,
        radius: Math.ceil(Math.pow(json.star.size, 2 / 5)),
        x: x,
        y: y,
        coord: json.coord,
        planets: [],
      };
      let distance = data.radius + 5;

      for (const planet of json.planets) {
        const radius = Math.ceil(Math.sqrt(planet.size));
        data.planets.push({
          size: planet.size,
          type: planet.type,
          color: planetColors[planet.type],
          radius: radius,
          distance: distance + radius,
        });
        distance += radius * 2 + 1;
      }
      data.offset = Math.abs(json.seed);
      c = data;
      sessionStorage.setItem(k, JSON.stringify(data));
    }

    return c;
  };

  const requestIdRef = useRef(null);

  const renderFrame = () => {
    if (!canvasRef.current) return;
    const t = Date.now() / 1000;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "black";
    ctx.fill();

    // Draw grid of solar systems
    for (let y = 0; y < gridSize.rows; y++) {
      for (let x = 0; x < gridSize.columns; x++) {
        const _X = BigInt(x - centerX) + X;
        const _Y = BigInt(y - centerY) + Y;
        const system = get_system(_X, _Y);
        if (system === undefined) continue;

        // Draw the central star
        ctx.fillStyle = system.color;
        ctx.font = "10px serif";
        ctx.beginPath();
        ctx.arc(
          (x + 0.5) * cellSize,
          (y + 0.5) * cellSize,
          system.radius,
          0,
          2 * Math.PI
        );
        ctx.imageSmoothingEnabled = true;
        ctx.fill();

        // Draw planets
        if (system.planets) {
          for (let k = 0; k < system.planets.length; k++) {
            const planet = system.planets[k];
            ctx.fillStyle = planet.color;
            const v = Math.sqrt(system.size / planet.distance / 10);
            let x_ =
              (x + 0.5) * cellSize +
              planet.distance * Math.cos(t * v + (system.offset % 10) * k);
            let y_ =
              (y + 0.5) * cellSize +
              planet.distance * Math.sin(t * v + (system.offset % 10) * k);
            ctx.beginPath();
            ctx.arc(x_, y_, planet.radius, 0, 2 * Math.PI);
            ctx.imageSmoothingEnabled = true;
            ctx.fill();
          }
        }
        ctx.fillStyle = "white";
        ctx.font = "20px serif";
        ctx.fillText(
          `${_X} ${_Y}`,
          (x + 0.05) * cellSize,
          (y + 0.1) * cellSize
        );
      }
    }
  };

  const tick = () => {
    if (!canvasRef.current) return;
    renderFrame();
    requestIdRef.current = requestAnimationFrame(tick);
  };

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (cookies.token === undefined) {
  //     navigate("/");
  //   }
  // }, []);

  const handleKeys = (event) => {
    if (event.shiftKey && event.key === "R") {
      setX(BigInt(0));
      setY(BigInt(0));
    }
    if (event.key === "Enter") {
      navigate(`/system`);
    }
    const step = BigInt(10000000);
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setX(X);
      setY(Y - step);
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setX(X);
      setY(Y + step);
    }
    if (event.key === "ArrowLeft") {
      event.preventDefault();
      setX(X - step);
      setY(Y);
    }
    if (event.key === "ArrowRight") {
      event.preventDefault();
      setX(X + step);
      setY(Y);
    }
  };

  useEffect(() => {
    if (requestIdRef) {
      cancelAnimationFrame(requestIdRef.current);
    }
    requestIdRef.current = requestAnimationFrame(tick);

    return () => {
      cancelAnimationFrame(requestIdRef.current);
    };
  }, [starSystems]);

  useEffect(() => {
    reload();
    document.addEventListener("keydown", handleKeys);
    document.addEventListener("resize", () => {
      if (requestIdRef) {
        cancelAnimationFrame(requestIdRef.current);
      }
      requestIdRef.current = requestAnimationFrame(tick);
      reload();
    });
    if (canvasRef.current) {
      canvasRef.current.addEventListener("click", openStarSystem);
      canvasRef.current.addEventListener("mouseover", drawSelectBox);
    }

    return () => {
      document.removeEventListener("keydown", handleKeys);
    };
  }, [X, Y]);

  return (
    <div className="m-auto bg-black min-h-screen flex h-screen justify-center items-center overflow-hidden">
      <canvas className="m-auto" {...size} ref={canvasRef} />
    </div>
  );
}

export default SpaceMap;
