import "index.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import App from "App";
import ErrorPage from "components/ErrorPage";
import LoginPage from "components/LoginPage";
import React from "react";
import ReactDOM from "react-dom/client";
import RegisterPage from "components/RegisterPage";
import SpaceMap from "components/SpaceMap";
import StarSystem from "components/StarSystem";
import reportWebVitals from "reportWebVitals";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/space",
    element: <SpaceMap />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/system",
    element: <StarSystem />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="bg-gray-200 min-h-screen">
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
